import type {InputHTMLAttributes} from 'react';
import {cva} from 'class-variance-authority';

import {twMerge} from '@/stylesheets/twMerge';
import {IconEnum} from '@/enums';

import Icon from '../Icon/Icon';

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label: string;
  isDisabled?: boolean;
  className?: string;
  mode?: 'light' | 'dark';
}

const checkIconStyles = cva('transition-transform delay-100', {
  variants: {
    mode: {
      light: 'text-white',
      dark: 'text-shade-90',
    },
  },
});

const inputStyles = cva(
  [
    'z-10 w-6 h-6 absolute cursor-pointer opacity-0 [&+div]:checked:ring-0',
    '[&+div>svg]:scale-0 [&+div>svg]:checked:scale-100',
    '[&+div]:focus-visible:outline [&+div]:focus-visible:outline-state-focus [&+div]:focus-visible:outline-[3px] [&+div]:focus-visible:outline-offset-[3px]',
    '[&+div]:disabled:bg-transparent [&+div]:disabled:hover:opacity-100',
  ],
  {
    variants: {
      mode: {
        light: [
          '[&+div]:checked:bg-lavender-70 [&+div]:hover:checked:opacity-80 [&+div]:active:bg-lavender-30 [&+div]:disabled:checked:bg-shade-30',
          '[&+div]:hover:ring-shade-50 [&+div]:active:ring-shade-70 [&+div]:disabled:ring-shade-30',
        ],
        dark: [
          '[&+div]:checked:bg-lavender-30 [&+div]:checked:active:bg-lavender-70 [&+div]:checked:hover:bg-lavender-50 [&+div]:disabled:checked:bg-shade-60',
          '[&+div]:hover:ring-shade-40 [&+div]:active:ring-shade-30 [&+div]:disabled:ring-shade-70 ',
        ],
      },
    },
  },
);

const checkboxStyles = cva('transition-all w-4 h-4 ring-1 rounded', {
  variants: {
    mode: {
      light: 'default:ring-shade-30',
      dark: 'default:ring-shade-60',
    },
  },
});

export default function Checkbox({
  mode,
  id,
  isDisabled,
  className,
  ...inputProps
}: CheckboxProps) {
  return (
    <>
      <input
        key={id}
        {...inputProps}
        className={inputStyles({mode})}
        id={id}
        name={inputProps.name || id}
        type="checkbox"
      />
      <div className={twMerge(checkboxStyles({mode}), className)}>
        <Icon
          className={checkIconStyles({mode})}
          icon={IconEnum.Check}
          size={16}
        />
      </div>
    </>
  );
}
